<template>
  <b-container fluid>
    <b-modal title="Agregar empleados" id="my-modal" size="lg">
      <!-- <h4 class="px-4 py-4"><b>DEVENGADOS</b></h4> -->
      <div class="row px-5">
        <div class="col-md-12">
          <div class="mb-4">
            <p>
              Selecciona los empleados que deseas incluir en este período de
              nómina.
            </p>
            <div class="row px-5 mb-4 mt-4">
              <div class="table-responsive px-4 mt-3">
                <!-- <table id="datatable_empleados2" class="table table-bordered table-responsive-md table-striped text-center">
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Salario</th>
                      <th>Identificación</th>
                      <th>Opciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in listaItems" :key="item.id">
                      <td>{{}}</td>
                      <td>{{}}</td>
                      <td>{{}}</td>
                      <td>
                        <span class="pointer">
                          <i @click="$router.push('departamentos_editar/' + item.id)" class="fas fa-solid fa-pen px-1 text-success"></i>
                          <i @click="deleteRow(item.id)" class="fas fa-solid fa-trash px-1 text-danger"></i>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex jsutify-content-end">
          <vs-button icon type="submit">Agregar</vs-button>
          <vs-button icon danger @click="$bvModal.hide('my-modal')"
            >Cancelar</vs-button
          >
        </div>
      </template>
    </b-modal>
    <form @submit.prevent="saveData">
      <b-row>
        <b-col sm="12">
          <card>
            <h4 class="px-4 py-4 text-center">
              <b>Nueva Liquidación nómina</b>
            </h4>
            <template v-slot:headerTitle> </template>
            <template>
              <div class="row text-center">
                <div class="offset-md-5 col-4 mt-3">
                  <div class="text-left">
                    <!-- <b-form-group v-slot="{ ariaDescribedby }" @change="seleccionaConcepto()"> -->
                    <b>CONCEPTOS GENERALES</b>

                    <b-form-radio-group
                      v-model="picked"
                      name="radio-options"
                      class="mt-3"
                    >
                      <b-form-radio
                        class="d-block"
                        v-for="(option, index) in options"
                        :key="index"
                        :value="option.value"
                        :disabled="option.disabled"
                        @change="seleccionaConcepto()"
                      >
                        {{ option.text }}
                      </b-form-radio>
                      <template v-if="cesantiaSeleccionada">
                        <b-form-radio
                          class="d-block ml-5"
                          v-for="optionCesantias in optionCesantias"
                          :key="optionCesantias.value"
                          :value="optionCesantias.value"
                          :disabled="optionCesantias.disabled"
                          @change="seleccionaConcepto"
                        >
                          {{ optionCesantias.text }}
                        </b-form-radio>
                      </template>
                    </b-form-radio-group>
                  </div>
                </div>
                <div class="col-6"></div>
                <h1></h1>
              </div>
            </template>
          </card>
          <card>
            <h4 class="px-4 py-4"><b>Período de la liquidación:</b></h4>
            <!-- <label for=" " class="form-label">Periodo de Liquidación</label> -->
            <div class="row px-5 mb-4">
              <div class="col-md-6">
                <label for=" " class="form-label">Año Liquidación</label><br />
                <b-form-select
                  @change="seleccionaConcepto()"
                  class="col-12"
                  required
                  v-model="anioSeleccionado"
                  text-field="descripcion"
                  value-field="anio"
                  :options="anios"
                >
                  <template #first>
                    <b-form-select-option selected :value="none" disabled
                      >-- selecciona una opción --</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </div>

              <div class="col-md-6">
                <label for=" " class="form-label">Estado</label>
                <input
                  disabled
                  :value="'por liquidar'"
                  required
                  placeholder=""
                  type="text"
                  class="form-control"
                  id=" "
                />
              </div>
            </div>

            <div class="row px-5 mb-4">
              <div class="col-md-6">
                <div class="mt-4">
                  <label for=" " class="form-label">Fecha inicio</label>
                  <input
                    :readonly="picked == 1 || picked == 2 || picked == 4"
                    required
                    v-model="item.FechaInicio"
                    placeholder=""
                    type="date"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>

              <div class="col-md-6">
                <div class="mt-4">
                  <label for=" " class="form-label">Fecha fin</label>
                  <input
                    :readonly="picked == 1 || picked == 2 || picked == 4"
                    v-model="item.FechaFin"
                    required
                    placeholder=""
                    type="date"
                    class="form-control"
                    id=" "
                  />
                </div>
              </div>
            </div>
          </card>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-end px-5 pb-5">
        <vs-button icon type="submit" class="mx-1"
          >Guardar Liquidación</vs-button
        >
        <vs-button
          danger
          icon
          class=""
          @click="$router.push('/helex/liquidacion')"
          >Cancelar</vs-button
        >
      </div>
    </form>
  </b-container>
</template>
<script>
// import { core } from "../../../../config/pluginInit";
// import { vsRadio } from "vuesax";
// import "vuesax/dist/vuesax.css";
// import Vue from "vue";
// Vue.use(vsRadio);

export default {
  name: "CrearLiquida",
  data() {
    return {
      usuario: { rol: { nivel: 0 } },
      id: "",
      selected: null,
      item: {
        FechaInicio: this.$moment
          .tz(new Date(), "America/Bogota")
          .format("YYYY-MM-DD"),
        FechaFin: this.$moment
          .tz(new Date(), "America/Costa_Rica")
          .format("YYYY-MM-DD"),
        CantidadEmpleados: 0.0,
        TotalSalarios: 0,
        TotalPagosAdicionales: 0,
        TotalDeducciones: 0,
        TotalCostos: 0,
        TotalAPagar: 0,
        EmpresaId: "",
        SucursalId: "",
        Descripcion: "",
        Estado: 1,
      },
      cesantiaSeleccionada: false,
      // ListSucursales: [],
      listaItems: [],
      picked: null,
      anios: [],
      anioSeleccionado: new Date().getFullYear(),
      options: [
        // { text: "Prima de servicios primer semestre", value: 1 },
        // { text: "Prima de servicios último semestre / Navideña", value: 2 },
        { text: "Nómina", value: 3 },
        // { text: "Cesantías", value: 0 },
        // {
        //   text: "Liquidación Cesantías",
        //   value: 0,
        //   list: [
        //     { text: "Cesantías anual", value: 4 },
        //     { text: "Cesantías x cambio de salario", value: 5 },
        //     { text: "Cesantías finalización de contrato", value: 6 },
        //   ],
        // },
      ],
      optionCesantias: [
        { text: "Fin de año", value: 4 },
        { text: "Cambio de salario a integral", value: 5 },
        { text: "Fin contrato laboral", value: 6 },
        // {
        //   text: "Liquidación Cesantías",
        //   value: 0,
        //   list: [
        //     { text: "Cesantías anual", value: 4 },
        //     { text: "Cesantías x cambio de salario", value: 5 },
        //     { text: "Cesantías finalización de contrato", value: 6 },
        //   ],
        // },
      ],
    };
  },
  async mounted() {
    this.$isLoading(true);
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.item.EmpresaId = this.usuario.empresa.id;
    let fecha = new Date().getFullYear();
    this.anios.push(fecha - 1);
    for (let index = 0; index < 7; index++) {
      //const element = array[index];
      this.anios.push(fecha + index);
    }

    //alert(this.anios);

    this.id = this.$route.params.id;
    //   await this.getData();
    // await this.getEmpresas();
    // await this.getSucursalEmpresa();
    // core.index();
    // window.$("#datatable_empleados").DataTable({
    //   language: {
    //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
    //   },
    // });

    // window.$("#datatable_empleados2").DataTable({
    //   language: {
    //     url: "//cdn.datatables.net/plug-ins/1.10.15/i18n/Spanish.json",
    //   },
    // });
    this.$isLoading(false);
  },
  methods: {
    seleccionaConcepto() {
      if (this.picked == 1) {
        this.cesantiaSeleccionada = false;
        this.item.FechaInicio = this.$moment
          .tz(this.anioSeleccionado + "-01-01", "America/Bogota")
          .format("YYYY-MM-DD");
        this.item.FechaFin = this.$moment
          .tz(this.anioSeleccionado + "-06-30", "America/Bogota")
          .format("YYYY-MM-DD");
        this.$forceUpdate();
      }
      if (this.picked == 2) {
        this.cesantiaSeleccionada = false;
        this.item.FechaInicio = this.$moment
          .tz(this.anioSeleccionado + "-07-01", "America/Bogota")
          .format("YYYY-MM-DD");
        this.item.FechaFin = this.$moment
          .tz(this.anioSeleccionado + "-12-31", "America/Bogota")
          .format("YYYY-MM-DD");
        this.$forceUpdate();
      }
      if (this.picked == 3) {
        this.cesantiaSeleccionada = false;
        this.item.FechaInicio = this.$moment
          .tz(this.anioSeleccionado + "-01-01", "America/Bogota")
          .format("YYYY-MM-DD");
        this.item.FechaFin = this.$moment
          .tz(this.anioSeleccionado + "-01-31", "America/Bogota")
          .format("YYYY-MM-DD");
        this.$forceUpdate();
      }

      if (
        this.picked == 0 ||
        this.picked == 4 ||
        this.picked == 5 ||
        this.picked == 6
      ) {
        if (this.picked == 0) {
          this.picked = 4;
        }
        if (this.picked == 4) {
          this.item.FechaInicio = this.$moment
            .tz(this.anioSeleccionado + "-01-01", "America/Bogota")
            .format("YYYY-MM-DD");
          this.item.FechaFin = this.$moment
            .tz(this.anioSeleccionado + "-12-31", "America/Bogota")
            .format("YYYY-MM-DD");
        }
        this.cesantiaSeleccionada = true;
        this.$forceUpdate();
      }
      this.item.TipoLiquidacion = this.picked;
    },
    // async getSucursalEmpresa() {
    //   //console.log(this.persona.empresa.id);
    //   try {
    //     console.log("get empresas...");
    //     let res = await this.$store.dispatch("hl_get", {
    //       path: "Sucursal/ListSucursalEmpresa/" + this.usuario.empresa.id,
    //     });
    //     console.log("post sucursal... ", res);
    //     if (res.length > 0) {
    //       this.ListSucursales = res;
    //     } else {
    //       this.ListSucursales = [];
    //     }
    //   } catch (error) {
    //     this.ListSucursales = [];
    //     console.log("err", error);
    //   }
    // },
    // async getEmpresas() {
    //   try {
    //     let res = await this.$store.dispatch("hl_post", {
    //       path: "Empresa/ListEmpresa/",
    //     });
    //     this.ListEmpresas = res;

    //     console.log("list", this.ListEmpresas);
    //   } catch (error) {
    //     this.ListEmpresas = {};
    //     console.log("err", error);
    //   }
    // },
    async saveData() {
      console.log("saving", this.item);
      try {
        this.$isLoading(true);

        //this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DDTHH:mm:ssZ")
        //this.item.EmpresaId = this.item.empresa.id;
        // this.item.municipio.departamentoPaisId = this.item.municipio.departamentoPais.id
        //this.item.Descripcion = this.item.descripcion;
        //this.item.SucursalId = this.item.sucursal.id;

        let res = await this.$store.dispatch("hl_post", {
          path: "NominaLiquidacion/CrearNominaLiquidacion/",
          data: this.item,
        });
        console.log("CREANDO LA LIQUIDACION PASO 1 ", res);
        //creando nomina
        //CrearNominaLiquidacionAutoEmpresa( int TipoLiquidacion, Guid? EmpresaIdP, DateTime? FInicial, DateTime? FFinal)
        let path = "";
        if (this.picked < 3) {
          path =
            "NominaLiquidacion/CalcularNominaLiquidacionPrimaServicios/" +
            this.picked +
            "/" +
            this.item.EmpresaId +
            "/" +
            this.item.FechaInicio +
            "/" +
            this.item.FechaFin;

          console.log("path", path);
        }

        if (this.picked == 3) {
          path =
            "NominaLiquidacion/CrearNominaLiquidacionAutoEmpresa/" +
            this.picked +
            "/" +
            this.item.EmpresaId +
            "/" +
            this.item.FechaInicio +
            "/" +
            this.item.FechaFin;
          console.log("path", path);
        }
        if (this.picked > 3) {
          path = "NominaLiquidacionesGeneradas/Cesantias/" + res;
          console.log("path", path);
        }

        await this.$store.dispatch("hl_post", {
          path: path,
        });
        console.log("post empresas...", res);

        this.$isLoading(false);
        if (res) {
          if (
            this.picked == 1 ||
            this.picked == 2 ||
            this.picked == 4 ||
            this.picked == 5 ||
            this.picked == 6
          ) {
            this.$router.push("/helex/liquidacion_editarv2/" + res);
          }
          if (this.picked == 3) {
            // this.$router.push("/helex/Preparar/" + res);
            this.$router.push({
              name: "PersonasNovedades",
              params: { liquidacionId: res },
            });
          }
          // if(this.picked)
        }
        this.$isLoading(false);

        // if (res) this.$router.push("/helex/Departamentos");
        // else alert("Verificar");
      } catch (error) {
        this.$isLoading(false);
        this.item = {};
        console.log("err", error);
      }
    },
  },
};
</script>
<style scoped>
.vs-radio {
  border: 1px solid rgb(200, 200, 200) !important;
}

.custom-radio {
  margin-bottom: 25px; /* Puedes ajustar el valor según tus necesidades */
}

.radio-block {
  display: block;
}
</style>
